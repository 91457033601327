import 'components/content-blocks/styles/customer-quote-box.scss';

type QuoteBoxProps = {
  className: string,
  company_info: string
  desktop_image: {
    source_url: string
  }
  mobile_image: {
    source_url: string
  }
  no_image: string,
  quote: string
}
const  QuoteBox = ({
  quote,
  company_info,
  desktop_image,
  mobile_image,
  className,
  no_image,
}:QuoteBoxProps) => (
  <section
    className={ `customer-quote-box ${className || ''} ${
      typeof no_image === 'undefined'
        ? ''
        : 'no-image'
    }` }
  >
    <div className="customer-quote-box-container">
      <img
        className="quotation-left"
        loading="lazy"
        src="https://marketing-assets.wheniwork-production.com/2019/10/21195459/Quotation-Left.png"
        alt="fill me out"
      />
      <img
        className="quotation-right"
        loading="lazy"
        src="https://marketing-assets.wheniwork-production.com/2019/10/21195527/Quotation-Right.png"
        alt="fill me out"
      />
      {typeof no_image === 'undefined' ? (
        <picture>
          <source
            srcSet={ desktop_image.source_url }
            media="(min-width: 769px)"
          />
          <img
            loading="lazy"
            src={ mobile_image.source_url }
            alt="fill me out"
          />
        </picture>
      ) : null}

      <div className="quote">
        <p>
          {`"${quote}" -`}
          <span>{company_info}</span>
        </p>
      </div>
    </div>
  </section>
);


export default QuoteBox;