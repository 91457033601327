import Layout from 'layouts/default';

import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';
import CustomerStories from 'components/templates/customer-stories';

import './styles/customer-stories.scss';

/*
This is a basic page template. 
In order for this template to work, don't create a file in /page that controls a page in wordpress.
If pages are created in gatsby then it will stop listening to this page template all together.
The following pages use the template:
- metro-dental
*/

type CustomerStoriesTemplateProps = {
  pageContext: any
}
const CustomerStoriesTemplate = ({ pageContext }:CustomerStoriesTemplateProps) => (
  <Layout>
    <Yoast { ...pageContext.yoast_head_json } />
    <div className="customer-stories-template-container" data-signal={ pageContext.wordpress_id }>
      <HeroDefaultSignup { ...pageContext.acf.hero } />
      <CustomerStories
        customerStoriesTemplate={ pageContext.acf.customer_stories_template }
        customerQuote={ pageContext.acf.customer_quote_1 }
      />
    </div>
  </Layout>
);

export default CustomerStoriesTemplate;