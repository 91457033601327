import CustomerQuote from 'components/content-blocks/customer-quote-box';
import FontIcon from 'components/icons';

type CustomerStoriesTemplateProps = {
  customerQuote?: any,
  customerStoriesTemplate: CustomerStoriesTemplate
}

type CustomerStoriesTemplate = {
  body_1: string,
  body_2: string,
  company_information: {
    logo: {
      source_url: string
    },
    products: {
      product: [],
      title: string
    },
    stats: []
  },
  problem: {
    copy: string,
    title: string
  },
  solution: {
    copy: string,
    title: string
  }
}

const CustomerStoriesTemplate = ({
  customerStoriesTemplate,
  customerQuote,
}:CustomerStoriesTemplateProps) => (
  <section className="customer-stories-template-section">
    <div className="container">
      <div className="the-pitch columns">
        <div className="column">
          <div className="marketing has-shadow-large">
            <h3>{customerStoriesTemplate.problem.title}</h3>
            <p>{customerStoriesTemplate.problem.copy}</p>
          </div>
        </div>
        <div className="column ">
          <div className="marketing has-shadow-large">
            <h3>{customerStoriesTemplate.solution.title}</h3>
            <p>{customerStoriesTemplate.solution.copy}</p>
          </div>
        </div>
      </div>
      <div className="company columns">
        <div className="company-stories column is-two-thirds">
          <div
            className="company-story"
            dangerouslySetInnerHTML={ {
              __html: customerStoriesTemplate.body_1,
            } }
          />
          <div
            className="company-story is-hidden-mobile"
            dangerouslySetInnerHTML={ {
              __html: customerStoriesTemplate.body_2,
            } }
          />
          {customerQuote === null || customerQuote?.quote === '' ? null : (
            <CustomerQuote { ...customerQuote } className="reverse" />
          )}

          <div
            className="company-story is-hidden-tablet"
            dangerouslySetInnerHTML={ {
              __html: customerStoriesTemplate.body_2,
            } }
          />
          <div className="products is-hidden-tablet">
            <h3 className="products-title">
              {customerStoriesTemplate.company_information.products.title}
            </h3>
            {customerStoriesTemplate.company_information.products.product.map(
              (product: {icon: string, name: string}, index) => (
                <div key={ index } className="product button">
                  <FontIcon icon={ product.icon } />
                  <span>{product.name}</span>
                </div>
              )
            )}
          </div>
        </div>
        <aside className="company-stats column">
          <div className="company-info">
            <img
              className="company-logo"
              src={
                customerStoriesTemplate.company_information.logo.source_url
              }
              alt="fill me out"
            />
            <hr className="is-hidden-mobile" />
            <ul className="company-data">
              {customerStoriesTemplate.company_information.stats.map(
                (data: {label: string, stat: string}, index) => (
                  <li key={ data.label + index }>
                    {data.label}
                    <span>{data.stat}</span>
                  </li>
                )
              )}
            </ul>

            <hr className="is-hidden-mobile" />
            <div className="products is-hidden-mobile">
              <h3 className="products-title">
                {customerStoriesTemplate.company_information.products.title}
              </h3>
              {customerStoriesTemplate.company_information.products.product.map(
                (product: {icon: string, name: string}, index) => (
                  <div key={ index } className="product button">
                    <FontIcon icon={ product.icon } />
                    <span>{product.name}</span>
                  </div>
                )
              )}
            </div>
          </div>
        </aside>
      </div>
    </div>
  </section>
);


export default CustomerStoriesTemplate;